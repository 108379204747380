<template lang="pug">
.inspiration-list__item(
  :class="{ 'is-loading': !isLoaded, 'is-preview': isPreview, 'is-hidden': !id }"
  :style="containerStyle"
  @mouseenter="isHovered = true"
  @mouseleave="isHovered = false; isVideoLoaded = false"
  @click="chooseTemplateMobile"
)
  template(v-if="id")
    template(v-if="!isMobile")
      .inspiration-list__item-hover
        span.text(v-if="videoPreview") Preview Video
        BaseButton(
          :is-primary="true"
          :is-canvas="true"
          iconBefore="icon-edit"
          @click="chooseTemplate"
        ) Edit
      BasePreloader(
        v-if="showVideoPreview && !isVideoLoaded"
      )
      video.inspiration-list__item-video(
        v-if="showVideoPreview"
        autoplay=true
        muted=true
        loop=true
        @load="isVideoLoaded = true"
      )
        source(:src="videoPreview")
    .scenes__canvas(
      :style="canvasStyle"
    )
      img.scenes__bg(
        v-if="previews.bg_layer"
        :src="previews.bg_layer"
        @load="bgLoaded = true"
      )
      CanvasElement(
        v-if="item && item.type"
        :item="item"
        :index="index"
        :isSelectable="false"
        :isScenePreview="true"
        animationTimeline="sceneTimeline"
        :sceneDuration="duration"
        :timelineSettings="item.timeline_settings"
        :sceneId="id"
        @element-loaded="elementLoaded"
      )
      img.scenes__fg-black(
        v-if="previews.fg_black"
        :src="previews.fg_black"
        @load="fgBlackLoaded = true"
      )
      img.scenes__fg-white(
        v-if="previews.fg_white"
        :src="previews.fg_white"
        @load="fgWhiteLoaded = true"
      )
      img.scenes__overlay(
        v-if="previews.overlay"
        :src="previews.overlay"
        @load="overlayLoaded = true"
      )
</template>
<script>
import { mapState, mapGetters, mapMutations } from 'vuex';
import cloneDeep from 'lodash.clonedeep';
import { getImageSize, isMobile } from '@/assets/scripts/utilities';
import { isDevMode } from '@/assets/scripts/variables'; // eslint-disable-line

import CanvasElement from '@/components/ProjectCanvas/CanvasContainer/children/CanvasElement.vue';

export default {
  name: 'InspirationListItem',
  components: {
    CanvasElement,
  },
  data() {
    return {
      fgBlackLoaded: true,
      fgWhiteLoaded: true,
      overlayLoaded: true,
      bgLoaded: true,
      heroLoaded: false,
      item: {},
      imageSize: {
        width: 0,
        height: 0,
      },
      isHovered: false,
      isMobile: isMobile(),
      isVideoLoaded: false,
    };
  },
  props: {
    index: { type: Number, default: 0 },
    // if id 0 means it doesnt have the right filter, eg. `is_hero_wo_bg` true is missing
    id: { required: true, type: Number, default: 0 },
    templateId: { type: Number, default: 0 },
    width: { type: Number, default: 0 },
    height: { type: Number, default: 0 },
    duration: { type: Number, default: 0 },
    previews: { type: Object, default: () => ({}) },
    background: { type: [String, Object], default: '#fff' },
    hero: { type: String, default: '' },
    isPreview: { type: Boolean, default: false },
    videoPreview: { type: String, default: '' },
  },
  computed: {
    ...mapState([
      'isLoggedIn',
    ]),
    ...mapState('inspirationList', [
      'heroUserFiles',
      'confirmRemoveBg',
    ]),
    ...mapGetters('canvasElements', [
      'getScenesElementsById',
    ]),
    ...mapState('userData', [
      'requiresSubscription',
    ]),
    ...mapGetters('userData', [
      'isPayingUser',
    ]),
    canvasWidth() {
      if (this.isPreview) return 165;
      if (this.height > this.width) return 240;
      return 300;
    },
    isWithoutBackground() {
      return this.confirmRemoveBg;
    },
    containerStyle() {
      const styleObject = {};
      const ratio = this.width / this.height;

      styleObject.width = `${this.canvasWidth}px`;
      styleObject.height = `${this.canvasWidth / ratio}px`;

      return styleObject;
    },
    canvasStyle() {
      const styleObject = {};
      const scale = this.canvasWidth / this.width;

      styleObject.width = `${this.width}px`;
      styleObject.height = `${this.height}px`;

      if (this.background) {
        if (this.background.points) {
          // Gradient
          styleObject.background = `linear-gradient(${this.background.degree}deg`;
          const { points } = this.background;

          for (let i = 0; i < points.length; i += 1) {
            const point = points[i];
            styleObject.background += `, ${point.color} ${point.percentage}%`;
          }
          styleObject.background += ')';
        } else if (this.background.color1) {
          // Old Gradient
          styleObject.background = `linear-gradient(${this.background.degree}deg, ${this.background.color1}, ${this.background.color2})`;
        } else if (this.background.color) {
          // Old color format { color: '#fff' }
          styleObject.background = this.background.color;
        } else {
          styleObject.background = this.background;
        }
      }

      if (scale > 0 && scale !== 1) {
        styleObject.transform = `scale(${scale})`;
      }

      return styleObject;
    },
    isLoaded() {
      if (this.id === 0) return true;
      return this.fgBlackLoaded && this.fgWhiteLoaded && this.bgLoaded && this.overlayLoaded && this.heroLoaded;
    },
    showVideoPreview() {
      if (this.isLoaded && this.videoPreview && this.isHovered && !this.isMobile) return true;
      return false;
    },
  },
  methods: {
    ...mapMutations([
      'setShowAuth',
      'setShowUpgradeSubscription',
      'setShowMobileSmartCreatePreview',
    ]),
    ...mapMutations('inspirationList', [
      'setSelectedInspirationListItem',
    ]),
    chooseTemplateMobile() {
      if (!this.isMobile) return;
      this.chooseTemplate();
    },
    chooseTemplate() {
      // console.log('InspirationListitem chooseTemplate', this.templateId);
      if (!this.isLoggedIn || this.isMobile) {
        this.setSelectedInspirationListItem({
          index: this.index,
          id: this.id,
          templateId: this.templateId,
          width: this.width,
          height: this.height,
          duration: this.duration,
          background: this.background,
          previews: this.previews,
          hero: this.hero,
          videoPreview: this.videoPreview,
        });
      }

      this.$emit('choose-template', this.templateId);
    },
    getItem() {
      const element = cloneDeep(this.getScenesElementsById(this.id).find(el => el.data.layerTag.hero));
      // console.log('getItem element', element);

      // console.log('element', element);
      if (!element
        || (this.imageSize.width === 0 && this.imageSize.height === 0)) return {};

      if (element.type === 'masks') {
        element.data.image.url = this.hero;
        element.data.image.isNew = true;
      } else {
        const ratio = this.imageSize.width / this.imageSize.height;
        let tempWidth = element.size.width;
        let tempHeight = tempWidth / ratio;

        if (tempHeight > element.size.height) {
          tempHeight = element.size.height;
          tempWidth = tempHeight * ratio;
        }

        element.size.width = tempWidth;
        element.size.height = tempHeight;
        element.data.url = this.hero;
        element.data.urlHd = this.hero;
        element.data.thumb = this.hero;
        element.data.image.top = 0;
        element.data.image.left = 0;
        element.data.image.width = '100%';
        element.data.image.height = '100%';
      }
      return element;
    },
    elementLoaded() {
      setTimeout(() => {
        this.heroLoaded = true;
      }, 750);
    },
  },
  beforeMount() {
    if (this.isMobile) {
      const windowWidth = window.innerWidth;
      const contentPadding = 40;
      const canvasMargin = 20;
      this.canvasWidth = (windowWidth - contentPadding) / 2 - canvasMargin;
    }

    getImageSize(this.hero, (width, height) => {
      this.imageSize.width = width;
      this.imageSize.height = height;
    });
    if (this.previews.bg_layer) this.bgLoaded = false;
    if (this.previews.fg_black) this.fgBlackLoaded = false;
    if (this.previews.fg_white) this.fgWhiteLoaded = false;
    if (this.previews.overlay) this.overlayLoaded = false;
  },
  mounted() {
    this.$emit('inspiration-list-item-loaded');
  },
  watch: {
    imageSize: {
      handler(val) {
        if (val.width) this.item = this.getItem();
      },
      immediate: true,
      deep: true,
    },
    hero: {
      handler() {
        this.item = this.getItem();
      },
      immediate: true,
      deep: true,
    },
  },
};
</script>

<style lang="scss">
.inspiration-list__item.is-hidden {
  display: none;
}

.inspiration-list__item:not(.is-hidden) {
  width: 300px;
  height: auto;
  display: inline-block;
  vertical-align: top;
  margin: 0 20px 40px 20px;
  overflow: hidden;
  position: relative;
  background: linear-gradient(120deg, white 40%, #ddd, white 60%);
  border-radius: 4px;
  box-shadow: 0 6px 15px rgba(50, 50, 50, 0.1);
  background-size: 400% 400%;

  @include mobile() {
    margin: 0 10px 20px 10px;
  };

  &.is-preview {
    width: 165px;
  }

  &.is-loading {
    animation: gradientBG 10s linear infinite;
    cursor: auto;

    .scenes__canvas {
      opacity: 0 !important;
    }
  }

  &:not(.is-loading) {
    transition: transform 0.2s ease-in-out;

    &:hover {
      transform: scale(1.05);

      .inspiration-list__item-hover {
        opacity: 1;
      }
    }
  }

  .scenes__canvas {
    transform-origin: top left;
    transition: opacity 0.35s ease-in-out;
    opacity: 1 !important;

    .scenes__fg-black,
    .scenes__fg-white,
    .scenes__bg,
    .scenes__overlay,
    .scenes__hero {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    .scenes__fg-white {
      mix-blend-mode: multiply;
    }

    .scenes__overlay,
    .scenes__fg-black {
      mix-blend-mode: screen;
    }
  }

  .preloader {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
  }
}

.inspiration-list__item-hover {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 60px;
  background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.6));
  display: flex;
  justify-content: space-between;
  align-items: center;
  opacity: 0;
  transition: opacity 0.25s ease-in-out;
  color: #fff;
  padding: 5px 15px;
  z-index: 4;

  .text {
    font-size: 0.875em;
  }

  .icon {
    font-size: 1.5em;
    display: inline-block;
    margin-right: 5px;
    margin-left: -8px;
  }
}

.inspiration-list__item-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 3;
}

@keyframes gradientBG {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
</style>
