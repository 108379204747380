<template lang="pug">
  .create-option
    h1.title What would you like to create?

    .create-option__list
      .create-option__container
        h2.create-option__title Product Video
        button.create-option__item(
          type="button"
          @click="smartCreate('images')"
        )
          img(src="@/assets/images/smart-create/upload-images.png")
          h3.create-option__title--action Upload Photo
          p People, product or business images
      .create-option__container
        h2.create-option__title Logo Intro
        button.create-option__item(
          type="button"
          @click="smartCreate('logos')"
        )
          img(src="@/assets/images/smart-create/upload-logo.png")
          h3.create-option__title--action
            | Select /
            br
            | Upload Logo
          p Your company's logo image
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'CreateOption',
  methods: {
    ...mapActions('inspirationList', [
      'setTmpUserId',
    ]),
    smartCreate(type) {
      this.$emit('smart-create', type);
    },
  },
  mounted() {
    this.setTmpUserId();
  },
};
</script>

<style lang="scss">
// .create-option__item is inside removeBgOption
.create-option {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 660px;
  padding: 20px;
  color: var(--black-white);

  @include mobile() {
    position: relative;
    top: 0;
    left: 0;
    transform: none;
    padding-top: 0;
  }
}

.create-option__title {
  margin-bottom: 2em;
}

.create-option__title--action {
  margin-top: 0;
  font-size: 1.25em;
  font-weight: 600;
  color: var(--black-white);
}
</style>

<style lang="scss" scoped>
.create-option {
  img {
    margin-bottom: 20px;
  }
}

.create-option__item {
  display: block;

  @include mobile() {
    img {
      width: 100px;
      display: inline-block;
      float: left;
    }

    .create-option__title--action {
      margin: 15px 0;
    }

    .create-option__title--action,
    p {
      display: inline-block;
      text-align: left;
      width: calc(100% - 120px);
      margin-left: 10px;
    }
  }
}
</style>
