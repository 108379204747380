<template lang="pug">
.size-options__container
  BaseButton.btn-size-option(
    v-if="hasSquare"
    :class="{ 'is-active': isSquare }"
    :is-canvas="true"
    isText=true
    isHollow=true
    @click="changeSize('square')"
  )
    .size-options__option.size-options__option--square
    span.size-options__option-text
      | Square
  BaseButton.btn-size-option(
    v-if="hasVertical"
    :class="{ 'is-active': isVertical }"
    :is-canvas="true"
    isText=true
    isHollow=true
    @click="changeSize('vertical')"
  )
    .size-options__option.size-options__option--vertical
    span.size-options__option-text
      | Vertical
  BaseButton.btn-size-option(
    v-if="hasLandscape"
    :class="{ 'is-active': isLandscape }"
    :is-canvas="true"
    isText=true
    isHollow=true
    @click="changeSize('landscape')"
  )
    .size-options__option.size-options__option--landscape
    span.size-options__option-text
      | Landscape
  BaseButton.btn-size-option(
    v-if="hasSeeMore"
    :class="{ 'is-active': isCustom }"
    :is-canvas="true"
    isText=true
    isHollow=true
    @click="changeSize('custom')"
    :iconBefore="iconBefore"
  ) See<br/>more
</template>

<script>
import BaseButton from '@/components/UI/BaseButton.vue';

export default {
  name: 'SizeOptions',
  components: { BaseButton },
  props: {
    size: { type: String, default: 'square' },
    hasSquare: { type: Boolean, default: true },
    hasVertical: { type: Boolean, default: true },
    hasLandscape: { type: Boolean, default: true },
    hasSeeMore: { type: Boolean, default: false },
  },
  data() {
    return {
      isSquare: false,
      isVertical: false,
      isLandscape: false,
    };
  },
  computed: {
    isCustom() {
      return !this.isSquare && !this.isVertical && !this.isLandscape;
    },
  },
  methods: {
    changeSize(size, emit = true) {
      let hasChanged = false;

      if (size === 'square' && !this.isSquare) {
        this.isSquare = true;
        hasChanged = true;
      } else if (size === 'vertical' && !this.isVertical) {
        this.isVertical = true;
        hasChanged = true;
      } else if (size === 'landscape' && !this.isLandscape) {
        this.isLandscape = true;
        hasChanged = true;
      } else if (size === 'custom' && !this.isCustom) {
        this.isSquare = false;
        this.isVertical = false;
        this.isLandscape = false;
        hasChanged = true;
      }

      if (emit && hasChanged) {
        this.$emit('size-changed', size);
      }
    },
  },
  mounted() {
    this.changeSize(this.size, false);
  },
  watch: {
    isSquare(val) {
      if (val) {
        this.isVertical = false;
        this.isLandscape = false;
      }
    },
    isVertical(val) {
      if (val) {
        this.isSquare = false;
        this.isLandscape = false;
      }
    },
    isLandscape(val) {
      if (val) {
        this.isSquare = false;
        this.isVertical = false;
      }
    },
  },
};
</script>

<style lang="scss">
.size-options__container {
  display: flex;
  justify-content: center;
  align-items: center;

  .btn {
    text-decoration: underline;
    margin-right: 10px;
    color: var(--darkgrey900-white);

    &:hover {
      color: var(--darkgrey900-white);
    }
  }

  .btn-size-option {
    position: relative;
    display: inline-flex;
    flex-direction: column;
    justify-content: space-evenly;
    border: 1px solid $borderGrey;
    text-decoration: none;
    width: 75px;
    height: 75px;
    margin: 5px;
    margin-top: 0;
    background: transparent;

    &:first-of-type {
      margin-left: 0;
    }
    &:last-of-type {
      margin-right: 0;
    }

    .size-options__option {
      margin-top: -15px;
      border: 2px solid var(--darkgrey800-white);
      border-radius: $componentBorderRadius;

      &--square {
        width: 15px;
        height: 15px;
      }
      &--vertical {
        width: 15px;
        height: 30px;
      }
      &--landscape {
        width: 30px;
        height: 15px;
      }
    }

    .size-options__option-text {
      position: absolute;
      bottom: 10px;
    }

    &:hover,
    &:focus,
    &.is-active {
      border-color: var(--blue700-green700);
      background: transparent;

      .icon {
        text-decoration: none;
      }
    }
  }
}
</style>
