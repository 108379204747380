<template lang="pug">
CanvasModal.smart-create-preview(
  isShow=true
  @closeOverlay="closeModal"
  :hideCloseButton="false"
  :isFillHeight="true"
  :isCenterContent="true"
)
  .smart-create-preview__content
    BasePreloader(
      v-if="isVideoLoading"
      :type="4"
      text="Loading preview..."
      :width="80"
    )
    video.smart-create-preview-video(
      autoplay=true
      muted=true
      loop=true
      ref="previewVideo"
    )
      source(:src="selectedInspirationListItem.videoPreview")
    p.smart-create-preview-text
      | This is a preview video of how the animation will look like.

  template(#bottom)
    BaseButton.btn-create-project(
      isFullWidth=true
      isPrimary=true
      isTall=true
      @click="createProject"
    ) Create project
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'MobileSmartCreatePreview',
  data() {
    return {
      isVideoLoading: true,
    };
  },
  computed: {
    ...mapState('inspirationList', [
      'selectedInspirationListItem',
    ]),
  },
  methods: {
    closeModal() {
      this.$emit('close-smart-create-preview');
    },
    createProject() {
      this.$emit('choose-template', this.selectedInspirationListItem.templateId);
    },
  },
  mounted() {
    const video = this.$refs.previewVideo;
    video.load();
    video.oncanplay = () => {
      this.isVideoLoading = false;
    };
  },
};
</script>

<style lang="scss">
.smart-create-preview {
  .smart-create-preview__content {
    .smart-create-preview-text {
      color: $timelineGrey;
      margin-top: 2em;
    }

    video {
      max-width: 100%;
    }
  }
}
</style>
