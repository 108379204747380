<template lang="pug">
.inspiration-list
  BackButton(
    @back="$emit('back')"
  )
  h1.title Choose your design
  a.inspiration-list__product-hunt(
    v-if="!isMobile"
    href="https://www.producthunt.com/posts/smart-create-by-offeo?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-smart-create-by-offeo"
    target="_blank"
  )
    img(
      src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=203094&theme=light"
      alt="Smart Create by OFFEO - Generate hundreds of animated designs with a single image. | Product Hunt Embed"
    )
  //-
    .inspiration-list__search
      .search
        input(
          type="text"
          placeholder="Search for templates"
          v-model="searchKeyword"
          @keydown.enter="runSearch"
        )
        button.btn-search(
          type="button"
          @click="runSearch"
        )
          i.icon.icon-search
      .dropdown(
        :class="{ 'is-show': isShow }"
        v-click-outside="onClickOutside"
      )
        button.btn-selected-format(
          type="button"
          @click="toggleIsShow"
        ) {{ formats[selectedFormatIndex].title }}
        .dropdown__list
          button.btn-format(
            v-for="(format, index) in formats"
            type="button"
            @click="changeSelectedFormat(index)"
          ) {{ format.title }}
  p.inspiration-list__description Start customizing to suit your campaign.

  SizeOptions(
    :size="selectedFormat"
    :hasLandscape="false"
    v-if="showNoDesigns || (preloaderProgressText === 100 && !isLogo)"
    @size-changed="sizeChanged"
  )

  .inspiration-list__container(
    :class="{ 'is-loading': preloaderProgressText !== 100 }"
  )
    div(v-if="showNoDesigns")
      h3 No relevant designs found.
    BasePreloader(
      v-if="preloaderProgressText !== 100 && !showNoDesigns"
      :type="preloaderType"
      :text="preloaderText"
      :width="120"
      :height="120"
      transition="fade"
    )
    .inspiration-list__progress(
      v-if="preloaderProgressText !== 100 && !showNoDesigns"
    ) {{ preloaderProgressText }}%
    .inspiration-list__items.fade(
      :class="{ 'is-show': preloaderProgressText === 100 }"
    )
      InspirationListItem(
        v-for="(scene, index) in getScenesWithPreviews"
        :key="`${scene.templateId}-${scene.id}`"
        :index="index"
        :id="scene.id"
        :templateId="scene.templateId"
        :width="scene.width"
        :height="scene.height"
        :duration="scene.duration"
        :background="scene.background"
        :previews="scene.previews"
        :videoPreview="scene.videoPreview"
        :hero="heroFiles"
        @choose-template="chooseTemplate"
        @inspiration-list-item-loaded="inspirationListItemLoaded"
      )
  .inspiration-list__pagination.fade(
    v-if="totalPages > 1 && getScenesWithPreviews.length !== 0"
    :class="{ 'is-show': preloaderProgressText === 100 }"
  )
    BaseButton.btn-next(
      v-if="hasPrev"
      :isDisabled="!hasPrev"
      isShort=true
      :is-canvas="true"
      @click="prevPage"
    ) Prev
    template(v-for="page in totalPages")
      //- only show the 5 prev and 5 next page
      template(v-if="page >= activePage - 5 && page <= activePage + 5")
        BaseButton.btn-page(
          @click="goToPage(page)"
          :extraClass="page === activePage ? 'is-active' : ''"
          isShort=true
          :is-canvas="true"
        ) {{ page }}
    BaseButton.btn-next(
      v-if="hasNext"
      :isDisabled="!hasNext"
      isShort=true
      :is-canvas="true"
      @click="nextPage"
    ) Next

  MobileSmartCreatePreview(
    v-if="showMobileSmartCreatePreview"
    @close-smart-create-preview="closeSmartCreatePreview"
    @choose-template="chooseTemplate"
  )
</template>

<script>
import {
  mapGetters, mapMutations, mapState, mapActions,
} from 'vuex';

import cloneDeep from 'lodash.clonedeep';
import vClickOutside from 'v-click-outside';
import { randomInt, isMobile } from '@/assets/scripts/utilities';
import { isDevMode } from '@/assets/scripts/variables';

import SizeOptions from '@/components/Common/SizeOptions.vue';
import InspirationListItem from '@/components/SmartCreate/children/InspirationListItem2.vue';
import MobileSmartCreatePreview from '@/components/ProjectCanvas/PageOverlay/MobileSmartCreatePreview.vue';
import BackButton from './children/BackButton2.vue';
import ProjectApi from '@/services/api/ProjectApi';
import uploadPermutationMixin from '@/components/mixins/upload-permutation-mixins';

import { permutableTypes } from '@/assets/scripts/enums';

import CollectionsApi from '@/services/api/CollectionsApi';

const MOCK_PROGRESS = 28;

export default {
  name: 'InspirationList',
  components: {
    SizeOptions,
    InspirationListItem,
    BackButton,
    MobileSmartCreatePreview,
  },
  mixins: [uploadPermutationMixin],
  directives: {
    clickOutside: vClickOutside.directive,
  },
  props: {
    selectedFormat: { type: String, default: 'square' },
    selectedTags: { type: Array, default: () => [] },
    formatChanged: { type: Boolean, default: false },
  },
  data() {
    return {
      sceneIds: [],
      sceneList: [],
      scenePreviews: {},
      isShow: false,
      formats: [
        {
          title: 'Square format',
          value: 'square',
        },
        {
          title: 'Landscape format',
          value: 'landscape',
        },
        {
          title: 'Vertical format',
          value: 'vertical',
        },
      ],
      selectedFormatIndex: 0,
      activePage: 1,
      totalElements: 0,
      totalPages: 1,
      totalDesigns: 0,
      keyword: '',
      postActionTemplateId: 0,
      preloaderText: '',
      preloaderProgressText: 0,
      preloaderProgress: MOCK_PROGRESS,
      preloaderTextList: [
        'Understanding your brand',
        'Analyzing data',
        'Crafting your videos',
      ],
      justSubscribed: false,
      justRegistered: false,
      isMobile: isMobile(),
      preloaderType: 5,
      preloaderTextIndex: 0,
      tags: [],
      showNoDesigns: false,
      designsPerPage: this.selectedFormat === 'vertical' ? 15 : 12,
    };
  },
  computed: {
    ...mapState([
      'isLoggedIn',
      'showMobileSmartCreatePreview',
    ]),
    ...mapGetters('canvasElements', [
      'getScenes',
    ]),
    ...mapGetters('userData', [
      'isPayingUser',
    ]),
    ...mapState('userData', [
      'userId',
      'requiresSubscription',
    ]),
    ...mapState('inspirationList', [
      'tmpUserId',
      'files',
      'templates',
      'heroIndex',
      'isLogo',
      'inspirationTemplateTags',
      'tmpUserFiles',
      'heroUserFiles',
      'confirmRemoveBg',
      'isTemporaryFile',
    ]),
    pagedScenesIds() {
      let pages = [];

      if (this.getScenes.length) {
        const startIndex = (this.activePage - 1) * this.designsPerPage;
        const endIndex = this.activePage * this.designsPerPage;

        pages = this.sceneIds.slice(startIndex, endIndex);
      }

      return pages;
    },
    hasPrev() {
      return this.activePage > 1;
    },
    hasNext() {
      return this.activePage < this.totalPages;
    },
    activeFormat() {
      return this.formats[this.selectedFormatIndex].value;
    },
    searchKeyword: {
      get() {
        return this.keyword;
      },
      set(value) {
        this.keyword = value;
      },
    },
    getScenesWithPreviews() {
      return this.getScenes.filter(scene => scene.previews);
    },
    heroFiles() {
      if (this.confirmRemoveBg) {
        return this.tmpUserFiles[0]?.data?.files?.w_960 || this.tmpUserFiles[0]?.data[0]?.w_960;
      }

      return this.files[0];
    },
    previewSceneFilter() {
      // force all logo to use hero_wo_bg temporarily
      return this.confirmRemoveBg || this.isLogo ? 'hero_wo_bg' : 'hero_w_bg';
    },
  },
  methods: {
    ...mapActions('canvasElements', [
      'updateScenesElements',
      'emptyScenesElements',
    ]),
    ...mapActions([
      'updateShowStoryboardOverlay',
    ]),
    ...mapMutations([
      'setShowAuth',
      'setShowRegister',
      'setShowLogin',
      'setShowUpgradeSubscription',
      'setShowMobileSmartCreatePreview',
      'setIsModalVisible',
      'setSmartCreateTrackingId',
    ]),
    ...mapMutations('canvasElements', [
      'updateProjectDetails',
      'updateCanvasSize', // mutation so that it doesn't save history
    ]),
    ...mapMutations('inspirationList', [
      'setTmpUserFiles',
      'setHeroUserFiles',
      'setInspirationTemplateTags',
      'setUserFileId',
    ]),
    axiosGetScene(id) {
      try {
        CollectionsApi.getDesignDetails(id)
          .then((response) => {
            if (response.data && response.data.success) {
              const { results } = response.data;
              if (results.scenes.length) {
                const filter = this.previewSceneFilter;

                const selectedScene = results.scenes.find(scene => scene[`is_${filter}`]);

                if (selectedScene) {
                  const scene = cloneDeep(selectedScene);

                  scene.templateId = results.id;
                  scene.width = results.canvas_width;
                  scene.height = results.canvas_height;
                  scene.previews = this.scenePreviews[results.id];
                  if (results.previews.videos) {
                    if (typeof results.previews.videos[scene.id] !== 'undefined' && results.previews.videos[scene.id]) {
                      // get by specific scene
                      scene.videoPreview = results.previews.videos[scene.id];
                    } else if (typeof results.previews.videos.thumb !== 'undefined' && results.previews.videos.thumb) {
                      // get default template thumbnail
                      scene.videoPreview = results.previews.videos.thumb;
                    }
                  }
                  this.sceneList.push(scene);
                } else {
                  console.error(`Error: template ${results.id} is missing ${filter}`);
                  // if doesn't have the right filter, push scene with id 0
                  this.sceneList.push({
                    id: 0,
                    templateId: results.id,
                    width: results.canvas_width,
                    height: results.canvas_height,
                    duration: 0,
                    background: '#fff',
                    previews: this.scenePreviews[results.id],
                    videoPreview: '',
                    hero: '',
                  });
                }
              }
            }
          }).catch((error) => {
            console.log(error);
            this.axiosGetScene(id);
          });
      } catch ($e) {
        console.log($e);
      }
    },
    getSceneList() {
      // only get the active page
      for (let i = 0; i < this.designsPerPage; i += 1) {
        // lets do set timeout to avoid server request clogging
        setTimeout(() => {
          const id = this.pagedScenesIds[i];
          if (typeof id !== 'undefined') this.axiosGetScene(id);
        }, (i * 300));
      }
    },
    getSceneIds() {
      // no file uploaded, do normal template request query
      const params = {
        type: 'modular,template',
      };

      // &format=${this.activeFormat}
      // for now product permutable template will all be square, and logo permutable template will all be hd
      params.format = this.isLogo ? 'hd' : this.selectedFormat;

      params.category = 'permutable';
      params.is_logo = this.isLogo;

      if (this.searchKeyword) {
        params.search = this.searchKeyword;
      }

      // console.log('this.inspirationTemplateTags', this.inspirationTemplateTags);

      this.tags = this.inspirationTemplateTags;

      if (this.inspirationTemplateTags.length) {
        params.tags = this.inspirationTemplateTags.join(',');
      }

      CollectionsApi.getItems('designs', params)
        .then((response) => {
          if (response.data && response.data.success) {
            const { results } = response.data;
            const filter = this.previewSceneFilter;

            // console.log('this.confirmRemoveBg', this.confirmRemoveBg, filter, this.confirmRemoveBg && !this.isLogo);
            // get all the ids
            for (let i = 0; i < results.length; i += 1) {
              const result = results[i];
              const { id } = result;
              // check whether this scene has previews
              // if not, don't include them
              if (result.previews && result.previews.images && result.previews.images[filter]) {
                this.scenePreviews[id] = result.previews.images[filter];
                this.sceneIds.push(id);
              }
            }
            this.totalDesigns = this.sceneIds.length;
            this.totalPages = Math.ceil(this.sceneIds.length / this.designsPerPage);

            console.log('getSceneList', this.totalDesigns, this.totalPages);

            if (this.totalDesigns > 0) {
              this.getSceneList();
            } else {
              this.showNoDesigns = true;
            }
          }
        }).catch((error) => {
          console.log(error);
        });
    },
    changeSelectedFormat(index) {
      this.selectedFormatIndex = index;
      this.isShow = false;
    },
    toggleIsShow() {
      this.isShow = !this.isShow;
    },
    onClickOutside() {
      this.isShow = false;
    },
    prevPage() {
      this.updatePreloaderProgress(true);
      this.activePage -= 1;
    },
    nextPage() {
      this.updatePreloaderProgress(true);
      this.activePage += 1;
    },
    goToPage(page) {
      this.updatePreloaderProgress(true);
      this.activePage = page;
    },
    runSearch() {
      // when searching with keyword,
      // clear all scenes
      this.emptyScenesElements();
      this.sceneList.length = 0;
      this.getSceneIds();
    },
    chooseTemplate(templateId) {
      console.log('chooseTemplate', templateId);
      this.postActionTemplateId = 0;

      if (!isDevMode() && !this.isLoggedIn) {
        this.postActionTemplateId = templateId;
        this.setIsModalVisible(true);
        this.setShowAuth(true);
        // this.setShowRegister(true);
        this.setShowRegister(false);
        this.setShowLogin(true);
        return;
      }

      if (this.justRegistered && !this.isPayingUser && this.requiresSubscription) {
        this.postActionTemplateId = templateId;
        this.setIsModalVisible(true);
        this.setShowUpgradeSubscription(true);
        return;
      }

      // Show Create Project Preview
      if (!this.justSubscribed && this.isMobile && !this.showMobileSmartCreatePreview) {
        this.setIsModalVisible(true);
        this.setShowMobileSmartCreatePreview(true);
        return;
      }

      // 5. canvas
      this.smartCreateTrackingUpdate({ step: 5 });

      this.$emit('next-step'); // preparing modal;

      // console.log('chooseTemplate', templateId);
      const params = {
        project_id: 'create_new_project',
        change_canvas_dimension: true,
        is_mobile: this.isMobile,
        just_subscribed: this.justSubscribed,
        // hero_image: this.heroUserFiles,
        // temporary_files: this.tmpUserFiles,
      };

      if (this.getTeamIdQueryUrl) params.team_id = this.getTeamIdQueryUrl;
      if (this.getFolderIdQueryUrl) params.project_folder = this.getFolderIdQueryUrl;

      ProjectApi.chooseTemplate(templateId, params)
        .then(async (response) => {
          if (response.data.success) {
            // Track create new project type
            await ProjectApi.trackCreateNewProject({
              data: 'smart-create',
            });

            const projectDetails = response.data.results;
            const assignNewId = true;

            this.updateScenesElements({
              assignNewId,
              canvasHeight: projectDetails.canvas_height,
              canvasWidth: projectDetails.canvas_height,
              newScenes: JSON.parse(JSON.stringify(projectDetails.scenes)),
              assignModularAsLastTemplate: true,
            });

            this.updateCanvasSize({
              width: projectDetails.canvas_width,
              height: projectDetails.canvas_height,
              notSmartResize: true,
            });

            this.updateProjectDetails(projectDetails);

            this.updateShowStoryboardOverlay(true);
            this.deleteTemporaryScene(projectDetails); // delete temporary scene first, then create new scene base on specific scene id

            this.setUserFileId(''); // no longer need this after user in canvas
            this.setSmartCreateTrackingId('');
          }
        }).catch((error) => {
          this.$emit('failed-step');
          console.log(error);
          // if failed, show the design option again
        });
    },
    deleteTemporaryScene(project) {
      const projectId = project.project_id;
      const sceneId = project.scenes[0].id;

      ProjectApi.deleteProjectScene(projectId, sceneId)
        .then((response) => {
          if (response.data.success) {
            this.createScene();
          }
        }).catch((error) => {
          console.log(error);
        });
    },
    createScene() {
      // console.log(this.heroUserFiles, this.tmpUserFiles);
      if (this.isLogo) {
        const additionalParams = {
          logoUrl: this.files[0],
        };
        this.addScene(permutableTypes.LOGO, additionalParams);
      } else {
        // const selectedImageObject = this.tmpUserFiles[0].data[0];
        // const heroData = {
        //   urlHd: selectedImageObject.download_link,
        //   url: selectedImageObject.w_960,
        //   thumb: selectedImageObject.thumb_link,
        // };

        // if the image is temporary file, pass the temporary user ide
        // else pass the actual loggedin user id to match with the user file id.
        const heroData = {
          temporary_file: this.tmpUserFiles[0].id, // pass the temporary file id so the admin will move the temporary file to user's file, before applying it inside the scene.
          user_id: this.isTemporaryFile ? this.tmpUserId : this.userId,
        };
        this.addScene('image', { heroData });
      }
    },
    updatePreloaderIndices() {
      if (this.preloaderType < 7) {
        this.preloaderType += 1;
      }
      if (this.preloaderTextIndex + 1 < this.preloaderTextList.length) {
        this.preloaderTextIndex += 1;
      }
      this.preloaderText = this.preloaderTextList[this.preloaderTextIndex];
    },
    updatePreloaderProgress(isReset = false) {
      if (isReset) {
        this.preloaderProgressText = 0;
        this.preloaderProgress = MOCK_PROGRESS;
      }

      if (this.preloaderProgressText < this.preloaderProgress && this.preloaderProgressText < 100) {
        if (this.preloaderProgressText === 10) {
          this.updatePreloaderIndices();
        } else if (this.preloaderProgressText === 27) {
          this.updatePreloaderIndices();
        }

        this.preloaderProgressText += 1;
        const randomInterval = randomInt(500, 1000);
        setTimeout(this.updatePreloaderProgress, randomInterval);
      }
    },
    inspirationListItemLoaded() {
      this.preloaderProgress += ((100 - MOCK_PROGRESS) / this.getScenesWithPreviews.length);
      this.updatePreloaderProgress();
      // console.log('inspirationListItemLoaded', this.preloaderProgress);

      if (this.activePage === 1 && this.preloaderProgress === 100) {
        // 3. selection in page 1
        this.smartCreateTrackingUpdate({ step: 3, selection_page: 1 });
      }
    },
    scrollToTop() {
      this.$nextTick(() => {
        document.documentElement.scrollTop = 0;
      });
    },
    closeSmartCreatePreview() {
      this.setIsModalVisible(false);
      this.setShowMobileSmartCreatePreview(false);
    },
    sizeChanged(size) {
      this.$emit('size-changed', { size, tags: this.tags });
    },
  },
  mounted() {
    if (this.formatChanged) {
      this.setInspirationTemplateTags(this.selectedTags);
    }

    this.preloaderText = this.preloaderTextList[this.preloaderTextIndex];
    this.updatePreloaderProgress(true);
  },
  watch: {
    sceneList: {
      handler(val) {
        const currentPageTotalDesign = ((this.activePage - 1) * this.designsPerPage) + val.length;
        // console.log('sceneList', val.length, this.totalPages, this.designsPerPage, currentPageTotalDesign, this.totalDesigns);
        // console.table({
        //   val: val.length,
        //   totalPages: this.totalPages,
        //   designsPerPage: this.designsPerPage,
        //   currentPageTotalDesign,
        //   totalDesigns: this.totalDesigns,
        // });

        /**
         * The conditions to display are:
         * - if the number of sceneList is same as this.designsPerPage
         * - if the current page is last page
         * - if the total designs received is less than this.designsPerPage and sceneList is the same as designs total received
         */
        if (val.length === this.designsPerPage
        || currentPageTotalDesign >= this.totalDesigns
        || (this.designsPerPage > this.totalDesigns && val.length === this.totalDesigns)
        ) {
          this.emptyScenesElements();
          this.setInspirationTemplateTags([]);
          this.updateScenesElements({
            newScenes: cloneDeep(val),
          });
        }
      },
      immediate: true,
    },
    activePage(val) {
      // when changing page,
      // clear all scenes
      this.emptyScenesElements();
      this.sceneList.length = 0;
      this.getSceneList();
      this.scrollToTop();

      if (val > 1) {
        // 4. waiting for page 2,3,4 to load
        this.smartCreateTrackingUpdate({ step: 4, selection_page: val });
      }
    },
    inspirationTemplateTags() {
      if (this.inspirationTemplateTags.length) {
        this.getSceneIds();
      }
    },
    isLoggedIn() {
      if (this.isLoggedIn && this.postActionTemplateId > 0) {
        this.$emit('just-registered');
        this.justRegistered = true;
        // Show Create Project Preview
        if (this.isMobile && !this.requiresSubscription) {
          this.setIsModalVisible(true);
          this.setShowMobileSmartCreatePreview(true);
        } else {
          this.chooseTemplate(this.postActionTemplateId);
        }
      }
    },
    isPayingUser() {
      if (this.isPayingUser && this.postActionTemplateId > 0) {
        this.$emit('just-subscribed');
        this.justSubscribed = true;
        this.setIsModalVisible(true);
        this.setShowMobileSmartCreatePreview(true);
        this.chooseTemplate(this.postActionTemplateId);
      }
    },
  },
};
</script>

<style lang="scss">
.inspiration-list {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: calc(100vh - 100px);
  width: 100%;
  max-width: 1400px;
  text-align: center;
  margin: 0 auto;
  position: relative;
  color: var(--black-white);

  .btn-back-create {
    left: 20px;
    top: 20px;

    @include mobile() {
      top: 0;
      left: 0;
    };
  }
}

.inspiration-list__product-hunt {
  position: absolute;
  top: 20px;
  right: 0;
  width: 240px;

  img {
    max-width: 100%;
  }

  @include non-desktop() {
    display: none;
  };
}

.inspiration-list__search {
  text-align: center;

  .search {
    display: inline-block;
    background: $lightGrey;
    width: 450px;
    position: relative;
    border-radius: $componentBorderRadius;

    input {
      width: 100%;
      background: transparent;
      border: 0;
      padding: 8px 30px 8px 8px;
    }

    .btn-search {
      position: absolute;
      font-size: 1.5em;
      line-height: 1;
      background: transparent;
      height: 100%;
      right: 0;
    }
  }

  .dropdown {
    position: relative;
    width: 250px;
    text-align: left;
    display: inline-block;
    vertical-align: middle;
    margin-left: 15px;
    z-index: 2;

    &.is-show {
      .btn-selected-format::before {
        transform: rotate(180deg);
      }

      .dropdown__list {
        opacity: 1;
        visibility: visible;
        transform: translateY(0);
      }
    }
  }

  .btn-selected-format {
    padding: 8px;
    border: 1px solid $defaultBorder;
    border-radius: $componentBorderRadius;
    width: 100%;
    text-align: left;
    font-size: 0.875em;

    &::before {
      content:  $icon-down-arrow;
      font-family: 'icomoon';
      position: absolute;
      right: 4px;
      top: 8px;
      font-size: 20px;
    }
  }

  .dropdown__list {
    position: absolute;
    background: #fff;
    opacity: 0;
    visibility: hidden;
    transform: translateY(-10px);
    box-shadow: 0px 26px 26px rgba(10, 31, 68, 0.12), 0px 0px 1px rgba(10, 31, 68, 0.1);
    width: 100%;
    transition: transform 0.25s ease-in-out,
              opacity 0.25s ease-in-out,
              visibility 0.25s ease-in-out;
  }

  .btn-format {
    padding: 10px;
    display: block;
    width: 100%;
    text-align: left;
    font-size: 0.875em;
    transition: background 0.25s ease-in-out;

    &:hover {
      background: $hoverGrey;
    }
  }
}

.inspiration-list__description {
  color: $grey;
  max-width: 800px;
  font-size: 0.875em;
  margin: 0 auto 20px;

  .inspiration-list__search {
    margin-top: 20px;
  }
}

.inspiration-list__container {
  margin-top: 30px;

  &.is-loading {
    flex: 1 1 auto;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    display: flex;
    margin-top: -20px;
  }

  .inspiration-list__progress {
    color: #BDBDBD;
    font-size: 1.25rem;
    margin-top: 10px;
  }

  .preloader__text {
    color: $timelineGrey;
    font-size: 1.25rem;
  }
}

.inspiration-list__pagination {
  .btn {
    margin: 0 2px;

    &.is-active,
    &:hover {
      background: var(--blue600-darkgrey900);
      color: $white;
    }
  }

  .btn-page {
    padding-left: 6px;
    padding-right: 6px;
    min-width: 40px;

    @include mobile() {
      min-width: 30px;
    };
  }

  .btn--next {
    @include mobile() {
      padding: 8px 12px;
    };
  }
}

.fade {
  opacity: 0;
  height: 0;
  overflow: hidden;

  &.is-show {
    opacity: 1;
    height: auto;
    transition: opacity 0.5s ease-in-out, visibility 0.5s ease-in-out;
  }
}
</style>
