<template lang="pug">
component.btn(
  :class="[{ 'btn--danger': isDanger,'btn--danger--hollow': isDangerHollow, 'btn--rounded': isRounded, 'btn--top-cap': isTopCap, 'btn--bottom-cap': isBottomCap, 'btn--left-cap': isLeftCap, 'btn--right-cap': isRightCap, 'btn--short': isShort, 'btn--primary': isPrimary, 'btn--primary--hollow': isPrimaryHollow, 'btn--success': isSuccess, 'btn--success--hollow': isSuccessHollow, 'btn--alert': isAlert, 'btn--alert--hollow': isAlertHollow, 'btn--confirm': isConfirm, 'btn--tall': isTall, 'btn--hollow': isHollow, 'btn--full': isFullWidth, 'btn--dark': isDark, 'btn--icon': isIcon, 'btn--salmon': isSalmon, 'btn--salmon--hollow': isSalmonHollow, 'btn--light-grey': isLightGrey, 'btn--text': isText, 'is-active': isActive, 'btn--borderless': isBorderless, 'is-loading': isLoading }, extraClass]"
  :is="type"
  :to="to"
  :href="href"
  :disabled="isDisabled"
  :type="type === 'button' ? type : ''"
  @click="$emit('click', $event)"
  :title="title"
  :target="newTab ? '_blank': false"
)
  i.icon.icon--before(
    :class="iconBefore"
    v-if="iconBefore"
  )
  template(v-if="!isLoading")
    slot
  template(v-else)
    img.preloader__image(
      src="@/assets/images/preloader/offeo_preloader_2.svg"
    )
  i.icon.icon--after(
    :class="iconAfter"
    v-if="iconAfter"
  )
</template>

<script>
/*
  This button can be used as router link, anchor or normal button
  For router link, pass `to`
  For anchor link, pass `href`
*/
export default {
  name: 'BaseButton',
  props: {
    extraClass: { type: String, default: '' },
    iconBefore: { type: String, default: '' },
    iconAfter: { type: String, default: '' },
    title: { type: String, default: '' },
    isActive: { type: Boolean, default: false },
    isDisabled: { type: Boolean, default: false },
    isAlert: { type: Boolean, default: false },
    isAlertHollow: { type: Boolean, default: false },
    isDanger: { type: Boolean, default: false },
    isDangerHollow: { type: Boolean, default: false },
    isPrimary: { type: Boolean, default: false },
    isPrimaryHollow: { type: Boolean, default: false },
    isSuccess: { type: Boolean, default: false },
    isSuccessHollow: { type: Boolean, default: false },
    isSalmon: { type: Boolean, default: false },
    isSalmonHollow: { type: Boolean, default: false },
    isLightGrey: { type: Boolean, default: false },
    isLink: { type: Boolean, default: false },
    isHollow: { type: Boolean, default: false },
    isTall: { type: Boolean, default: false },
    isShort: { type: Boolean, default: false },
    isDark: { type: Boolean, default: false },
    isRounded: { type: Boolean, default: false },
    isLeftCap: { type: Boolean, default: false },
    isRightCap: { type: Boolean, default: false },
    isTopCap: { type: Boolean, default: false },
    isBottomCap: { type: Boolean, default: false },
    isFullWidth: { type: Boolean, default: false },
    isIcon: { type: Boolean, default: false },
    isText: { type: Boolean, default: false },
    isConfirm: { type: Boolean, default: false },
    to: { required: false },
    href: { required: false },
    newTab: { type: Boolean, required: false, default: false },
    isLoading: { type: Boolean, default: false },
    isBorderless: { type: Boolean, default: false },
  },
  computed: {
    type() {
      if (this.to) {
        return 'router-link';
      }
      if (this.href) {
        return 'a';
      }
      return 'button';
    },
  },
};
</script>

<style lang="scss">
// Button
.btn {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 8px 20px;
  border-radius: $componentBorderRadius;
  background: $btnBackground;
  color: $light;
  font-family: $baseFont;
  font-weight: 500;
  font-size: 0.75em;
  transition: color 0.3s cubic-bezier(0, 0, 0, 1), background 0.3s cubic-bezier(0, 0, 0, 1);
  cursor: pointer;
  outline: 0;
  border: 0;
  line-height: 1.2;
  text-decoration: none;

  .icon--before {
    margin-right: 5px;
    margin-left: -5px;
  }

  .preloader__image {
    width: 16px;
    margin: 0;
  }

  .icon--after {
    margin-left: 5px;
    margin-right: -5px;
  }

  &:focus {
    background: $btnBackground;
  }

  &:active,
  &.is-active,
  &.is-selected,
  &:hover {
    transition: background 0.3s cubic-bezier(0, 0, 0, 1);
    background: $btnHover;
  }

  &:disabled {
    pointer-events: none;
    background: $btnDisabled;
    color: $textGrey;

    &:active,
    &.is-active,
    &:hover {
      background: $btnDisabled;
    }
  }
}

.btn--tall {
  font-size: 0.875em;
  padding-top: 11px;
  padding-bottom: 11px;
}

.btn--short {
  padding: 8px 15px;
}

.btn--hollow {
  background: transparent;
  color: $textBlack;
  border: 1px solid $defaultBorder;

  &:focus {
    background: transparent;
  }

  &:active,
  &.is-active,
  &.is-selected,
  &:hover {
    background: $btnHollowHover;
  }

  &.btn--dark {
    color: $textLight;
    border: 1px solid $darkGreyBorder;
    background-color: transparent;

    &:active,
    &.is-active,
    &.is-selected {
      background: $btnHollowDarkFocus;
    }
    &:hover {
      background: $btnHollowDarkHover;
    }
  }
}

.btn--dark {
  color: $textLight;
  border: 1px solid $darkGreyBorder;
  background-color: $darkGreyBorder;

  &:focus {
    background: $darkGreyBorder;
  }

  &:focus {
    background-color: $darkGreyBorder;
  }

  &:active,
  &.is-active,
  &.is-selected {
    background: $btnHollowDarkFocus;
  }
  &:hover {
    background: $btnHollowDarkHover;
  }
}

.btn--alert {
  background: $btnAlertBackground;
  color: $textBlack;

  &:focus {
    background: $btnAlertBackground;
  }

  &:active,
  &.is-active,
  &.is-selected,
  &:hover {
    background: $btnAlertHover;
  }

  &:disabled {
    background: $btnDisabled;
    color: $textGrey;

    &:active,
    &.is-active,
    &:hover {
      background: $btnDisabled;
    }
  }
}

.btn--alert--hollow {
  background: transparent;
  color: $btnAlertHollowText;
  border: 1px solid $btnAlertHollowText;

  &:focus {
    background: transparent;
  }

  &:active,
  &.is-active,
  &.is-selected,
  &:hover {
    background: $btnAlertHollowHover;
  }
}

.btn--danger {
  background: $btnDangerBackground;
  color: $light;

  &:focus {
    background: $btnDangerBackground;
  }

  &:active,
  &.is-active,
  &.is-selected,
  &:hover {
    background: $btnDangerHover;
  }

  &:disabled {
    background: $btnDisabled;
    color: $textGrey;

    &:active,
    &.is-active,
    &:hover {
      background: $btnDisabled;
    }
  }
}

.btn--danger--hollow {
  background: transparent;
  color: $btnDangerHollowText;
  border: 1px solid $btnDangerHollowText;

  &:focus {
    background: transparent;
  }

  &:active,
  &.is-active,
  &.is-selected,
  &:hover {
    background: $btnDangerHollowHover;
  }
}

.btn--success {
  background: $btnSuccessBackground;
  color: $light;

  &:focus {
    background: $btnSuccessBackground;
  }

  &:active,
  &.is-active,
  &.is-selected,
  &:hover {
    background: $btnSuccessHover;
  }

  &:disabled {
    background: $btnDisabled;
    color: $textGrey;

    &:active,
    &.is-active,
    &:hover {
      background: $btnDisabled;
    }
  }
}

.btn--success--hollow {
  background: transparent;
  color: $btnSuccessHollowText;
  border: 1px solid $btnSuccessHollowText;

  &:focus {
    background: transparent;
  }

  &:active,
  &.is-active,
  &.is-selected,
  &:hover {
    background: $btnSuccessHollowHover;
  }
}

.btn--primary {
  background: $btnPrimaryBackground;
  color: $light;

  &:focus {
    background: $btnPrimaryBackground;
  }

  &:active,
  &.is-active,
  &.is-selected,
  &:hover {
    background: $btnPrimaryHover;
  }

  &:disabled {
    background: $btnDisabled;
    color: $textGrey;

    &:active,
    &.is-active,
    &:hover {
      background: $btnDisabled;
    }
  }
}

.btn--primary--hollow {
  background: transparent;
  color: $btnPrimaryHollowText;
  border: 1px solid $btnPrimaryHollowText;

  &:focus {
    background: transparent;
  }

  &:active,
  &.is-active,
  &.is-selected,
  &:hover {
    background: $btnPrimaryHollowHover;
  }

  &:disabled {
    border-color: $btnDisabled;
  }
}

.btn--salmon {
  background: $salmon;
  border: 1px solid $salmon;

  &:focus {
    background: $salmon;
  }

  &:active,
  &.is-active,
  &.is-selected,
  &:hover {
    background: $salmonHover;
  }

  &:disabled {
    border-color: $btnDisabled;
  }
}

.btn--salmon--hollow {
  background: transparent;
  color: $salmon;
  border: 1px solid $salmon;

  &:focus {
    background: transparent;
  }

  &:active,
  &.is-active,
  &.is-selected,
  &:hover {
    background: $salmonHollowHover;
  }

  &:disabled {
    border-color: $btnDisabled;
  }
}

.btn--light-grey {
  background: $lightGrey;
  color: $blue;

  &:focus {
    background: $lightGrey;
  }

  &:active,
  &.is-active,
  &.is-selected,
  &:hover {
    background: darken($lightGrey, 5%);
  }

  &:disabled {
    border-color: $btnDisabled;
  }
}

.btn--confirm {
  background: $confirm;
  color: $light;

  &:focus {
    background: $confirm;
  }

  &:active,
  &.is-active,
  &.is-selected,
  &:hover {
    background: $confirmHover;
  }

  &:disabled {
    background: $btnDisabled;
    color: $textGrey;

    &:active,
    &.is-active,
    &:hover {
      background: $btnDisabled;
    }
  }
}

.btn--rounded {
  border-radius: 100px;
}

.btn--full {
  width: 100%;
}

.btn--icon,
.btn--text {
  background: transparent;
  font-weight: 500;
  color: $darkGrey;
  padding: 2px;
  transition: color 0.3s cubic-bezier(0, 0, 0, 1), background 0.3s cubic-bezier(0, 0, 0, 1);

  &.btn--primary--hollow {
    color: $btnPrimaryHollowText;
  }

  &.btn--salmon {
    color: $salmon;

    &:hover {
      color: $red;
    }
  }

  &.btn--danger {
    color: $btnDangerBackground;
  }

  &:hover {
    background: $btnHollowDarkHover;
  }
}

.btn--icon {
  &:disabled {
    color: $disabledGrey;
    pointer-events: none;
    cursor: auto;
  }
}

.btn--text {
  font-size: 0.75em;
}

.btn--left-cap {
  border-radius: $componentBorderRadius 0 0 $componentBorderRadius;
}

.btn--right-cap {
  border-radius: 0 $componentBorderRadius $componentBorderRadius 0;
}

.btn--top-cap {
  border-radius: $componentBorderRadius $componentBorderRadius 0 0;
}

.btn--bottom-cap {
  border-radius: 0 0 $componentBorderRadius $componentBorderRadius;
}

.btn--facebook {
  background: $facebookBlue;

  &:active,
  &.is-active,
  &.is-selected,
  &:hover {
    background: $facebookBlue;
  }
}

.btn--google {
  background: $googleRed;

  &:active,
  &.is-active,
  &.is-selected,
  &:hover {
    background: $googleRed;
  }
}

.btn--borderless {
  border: none;
}
</style>
