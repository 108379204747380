<template lang="pug">
#app.smart-create
  #main-header
    BaseHeader
      .header__slot-container
        BaseButton.btn-go-projects(
          v-if='!isMobile && isLoggedIn && !justRegistered',
          :is-canvas="true"
          @click='goToProjects',
          icon-before='icon-left-arrow-long'
        )
          span Back to Projects
  AccountController(
    v-if="showAccountController"
  )
  #main-content(v-else-if="isPageLoaded")
    template(v-if="isFetchingUserDetails")
      BasePreloader

    template(v-else)
      CreateOption(@smart-create='smartCreate', v-if='step === 1')
      UploadImages(
        v-if='step === 2',
        :maxImages='_maxImages',
        @next-step='step = 3',
        @back='step = 1'
      )
      InspirationList(
        v-if='step === 3',
        @back='step = 2',
        @next-step='step = 4',
        @failed-step='step = 3',
        @just-subscribed='justSubscribed = true',
        @just-registered='justRegistered = true',
        @size-changed='updateInspirationList',
        :key='inspirationListFormat',
        :selectedFormat='inspirationListFormat',
        :selectedTags='inspirationListTags',
        :formatChanged='formatChanged'
      )

      Freshchat(v-if="loadFreshchat")

    CanvasPreloader(v-if='hasDemo', text='Personalizing...')

    CanvasPreloader(
      v-if='step === 4 && !showMobileFinalScreen',
      text='Creating project'
    )

    template(v-if='showAuth')
      template(v-if='!isLoggedIn')
        //-
          Register(
            v-if='showRegister',
            headerText='Sign up for free and start editing your design',
            @close-register='closeRegister'
          )
        Login(v-if='showLogin', @close-login='closeLogin')
        ForgotPassword(
          v-if='showForgotPassword',
          @close-forgot-password='closeForgotPassword'
        )

    //-
      Subscribe(
        v-if='!isPayingUser && showUpgradeSubscription',
        :isCloseable='true',
        @close-subscribe='closeSubscribe'
      )

    MobileFinalScreen(
      v-if='showMobileFinalScreen',
      :successfulPayment='justSubscribed'
    )

    Notification
    template(v-if='showDeleteUserFileModal')
      BaseDialog(
        :isShow='true',
        :isDanger='true',
        :text='deleteConfirmationText',
        @confirm='confirmDelete',
        @closeOverlay='cancelDeleteUserFile'
      )

    BaseDialog(
      :isShow='showRemoveBgFailedDialog',
      :isFullWidthButtons='true',
      :text='failedRemoveBgText',
      button1Text='Choose another image',
      button2Text='Use current image',
      :hideCloseButton='true',
      @confirm='chooseAnotherImage',
      @closeOverlay='setShowRemoveBgFailedDialog(false)'
    )
  BasePreloader.full-page-preloader(v-else)
</template>

<script>
import {
  mapGetters, mapMutations, mapState, mapActions,
} from 'vuex';

import { isProduction, canvasSizesArray } from '@/assets/scripts/variables';
import { isMobile } from '@/assets/scripts/utilities';

import CanvasPreloader from '@/components/ProjectCanvas/PageOverlay/CanvasPreloader.vue';
import CreateOption from '@/components/SmartCreate/CreateOption2.vue';
import UploadImages from '@/components/SmartCreate/UploadImages2.vue';
import InspirationList from '@/components/SmartCreate/InspirationList2.vue';
import MobileFinalScreen from '@/components/SmartCreate/MobileFinalScreen.vue';
import Register from '@/components/ProjectCanvas/PageOverlay/Register.vue';
import Login from '@/components/ProjectCanvas/PageOverlay/Login.vue';
import ForgotPassword from '@/components/ProjectCanvas/PageOverlay/ForgotPassword.vue';
import Notification from '@/components/ProjectCanvas/PageOverlay/Notification.vue';
import Subscribe from '@/components/ProjectCanvas/PageOverlay/Subscribe.vue';
import AccountController from '@/components/Common/AccountController.vue';
import Freshchat from '@/components/Common/Freshchat.vue';

import Api from '@/services/api/Api';
import UserApi from '@/services/api/UserApi';

/* eslint-disable */
import Vue from 'vue';
import * as Sentry from '@sentry/browser';
import * as Integrations from '@sentry/integrations';
import VueFreshchat from 'vue-freshchat';
import VueCookies from 'vue-cookies';
/* eslint-enable */

import uploadPermutationMixin from '@/components/mixins/upload-permutation-mixins';
import prodScriptsMixin from '@/components/mixins/prod-scripts-mixins';

export default {
  name: 'SmartCreate',
  mixins: [uploadPermutationMixin, prodScriptsMixin],
  components: {
    CanvasPreloader,
    CreateOption,
    UploadImages,
    InspirationList,
    MobileFinalScreen,
    Register,
    Login,
    ForgotPassword,
    Notification,
    Subscribe,
    AccountController,
    Freshchat,
  },
  data() {
    return {
      step: 1,
      _maxImages: 1, // eslint-disable-line
      showMobileFinalScreen: false,
      justSubscribed: false,
      justRegistered: false,
      isMobile: isMobile(),
      validTypes: ['logos', 'images'],
      hasDemo: false,
      isFetchingUserDetails: true,
      inspirationListFormat: 'square',
      inspirationListTags: [],
      formatChanged: false,
      isPageLoaded: false,
      loadFreshchat: false,
    };
  },
  computed: {
    ...mapState([
      'isLoggedIn',
      'showAuth',
      'showLogin',
      'showRegister',
      'showForgotPassword',
      'showUpgradeSubscription',
      'isModalVisible',
      'smartCreateTrackingId',
    ]),
    ...mapState('userData', ['requiresSubscription', 'userId']),
    ...mapState('assetsSidebar', [
      'showDeleteUserFileModal',
      'deleteConfirmationText',
      'deleteItemId',
    ]),
    ...mapState('inspirationList', [
      'showRemoveBgFailedDialog',
      'failedRemoveBgImageUrl',
      'tmpUserId',
    ]),
    ...mapGetters('userData', ['isAdmin', 'isDesigner', 'isFreeUser', 'isPayingUser']),
    ...mapGetters('team', ['isInTeamWorkspace']),
    failedRemoveBgText() {
      return `${
        this.failedRemoveBgImageUrl !== ''
          ? `<img class="remove-bg__failed-image"" src="${this.failedRemoveBgImageUrl}">`
          : ''
      }<h3>Unable to remove background</h3><p>Try choosing another image with<br/>a clear foreground subject.</p>`;
    },
    showAccountController() {
      return !this.isLoggedIn || (this.isFreeUser && !this.getTeamIdQueryUrl);
    },
    isFreshchatEnabled() {
      // eslint-disable-next-line
      return isProduction() && window.location !== '/capture-frame' && !isMobile() && process.env.VUE_APP_ENABLE_FRESHCHAT === 'true' && !this.isAdmin && !this.isDesigner;
    },
  },
  methods: {
    ...mapMutations([
      'setShowRegister',
      'setShowLogin',
      'setShowForgotPassword',
      'setIsLoggedIn',
      'setShowUpgradeSubscription',
      'setIsModalVisible',
      'setSmartCreateTrackingId',
      'setSignUpPage',
      'setLoginOptions',
      'setOffeoTheme',
    ]),
    ...mapMutations('userData', [
      'setUserData',
    ]),
    ...mapMutations('inspirationList', ['setIsLogo', 'setShowRemoveBgFailedDialog']),
    ...mapMutations('assetsSidebar', [
      'setShowDeleteUserFileModal',
      'setActiveGroup',
      'setActiveSubGroup',
    ]),
    ...mapActions('assetsSidebar', ['confirmDeleteUserFile']),
    ...mapActions('inspirationList', ['setTmpUserId']),
    ...mapActions('team', ['initTeam']),
    smartCreate(type) {
      this.setActiveGroup('my-files');
      this.$nextTick(() => {
        this.setActiveSubGroup(type);
      });
      if (type === 'images') {
        this._maxImages = 1; // eslint-disable-line
        this.setIsLogo(false);
      } else {
        this._maxImages = 1; // eslint-disable-line
        this.setIsLogo(true);
      }

      // 1. upload image
      this.smartCreateTrackingUpdate({ step: 1 });

      this.step = 2;
    },
    goToProjects() {
      window.location.href = `${process.env.VUE_APP_DASHBOARD_URL}`;
    },
    cancelDeleteUserFile() {
      this.setShowDeleteUserFileModal(false);
    },
    confirmDelete() {
      this.confirmDeleteUserFile();

      // since we need to remove the item from here and not vuex store
      // hide the delete modal now instead of after api done
      this.setShowDeleteUserFileModal(false);
      this.$root.$emit('delete-user-file', this.deleteItemId);
    },
    chooseAnotherImage() {
      // if the remove.bg failed, option to choose another image
      this.setShowRemoveBgFailedDialog(false);
      this.step = 2;
    },
    closeRegister() {
      this.setIsModalVisible(false);
      this.setShowRegister(false);
    },
    closeLogin() {
      this.setIsModalVisible(false);
      this.setShowLogin(false);
    },
    closeForgotPassword() {
      this.setIsModalVisible(false);
      this.setShowForgotPassword(false);
    },
    closeSubscribe() {
      this.setIsModalVisible(false);
      this.setShowUpgradeSubscription(false);
    },
    updateInspirationList(emitData) {
      const { size, tags } = emitData;
      this.inspirationListFormat = size;
      if (tags.length) {
        this.inspirationListTags = tags;
      }
      this.formatChanged = true;
    },
    onAlmostDone() {
      this.showMobileFinalScreen = true;
    },
  },
  beforeCreate() {
    if (this.isLoggedIn) {
      this.setExternalScripts();
    }
  },
  beforeMount() {
    const offeoThemeCookie = process.env.VUE_APP_OFFEO_THEME;
    const getOffeoTheme = VueCookies.get(offeoThemeCookie);
    const offeoTheme = getOffeoTheme ? getOffeoTheme : 'dark'; // eslint-disable-line
    document.documentElement.setAttribute('data-theme', offeoTheme);

    document.body.classList.add('no-scroll');

    UserApi.userDetails()
      .then((response) => {
        const { results } = response.data;
        const userLevel = results.subscription_level;
        const userRole = results.role_id;
        const userId = results.id;
        const userEmail = results.email;
        const profilePicture = results.avatar;
        const createdAt = results.created_at;
        const userRegistrationData = results.created_at;
        const storageSpaceLeft = results.storage_space_left;
        const removeBgCredits = results.remove_bg_credits;
        const usedNewPricing = results.used_new_pricing;
        const userName = results.name;
        const userUsername = results.username;

        const isNotified = results.is_notified;
        const isNotifiedOfExports = results.is_notified_of_exports;
        const isSubscribed = results.is_subscribed;
        const activeProjects = results.active_projects;
        const exportedProjectsAsVideos = results.exported_projects_as_videos;
        const exportedProjectsAsImages = results.exported_projects_as_images;
        const currentPlan = results.current_plan;
        const previousPlan = results.previous_plan;
        const customerId = results.customer_id;
        const subscriptionId = results.subscription_id;
        const ltdTransactionId = results.ltd_transaction_id;
        const latestProjects = results.latest_projects;
        const teamSeats = results.team_seats;
        const hasSetupBrand = results.has_setup_brand;

        this.setUserData({
          userLevel,
          userRole,
          name: userName,
          username: userUsername,
          profilePicture,
          userId,
          storageSpaceLeft,
          userEmail,
          removeBgCredits,
          usedNewPricing,
          isNotified,
          isNotifiedOfExports,
          isSubscribed,
          activeProjects,
          exportedProjectsAsVideos,
          exportedProjectsAsImages,
          currentPlan,
          previousPlan,
          customerId,
          subscriptionId,
          ltdTransactionId,
          latestProjects,
          teamSeats,
          hasSetupBrand,
          createdAt,
        });

        this.setLoginOptions(response.data.user_login_options);

        UserApi.getPreferences()
          .then((preferencesResponse) => {
            const { theme } = preferencesResponse.data.results;

            VueCookies.set(process.env.VUE_APP_OFFEO_THEME, theme);
            this.setOffeoTheme(theme);

            document.documentElement.setAttribute('data-theme', theme);
          })
          .catch((error) => {
            console.error(error);
          });

        this.setIsLoggedIn(true);
        this.setExternalScripts();

        // Only need these integrations in production
        /* eslint-disable */
        if (isProduction()) {
          // Only need these integrations if not designer
          if (!this.isDesigner) {
            if (process.env.VUE_APP_ENABLE_WISDOM === 'true') {
              // Wisdom integration
              wisdom('identify', userId);
              wisdom('setUserInfo', {
                email: userEmail,
                firstName: userName,
                traits: {
                  user_type: userLevel,
                  user_role: userRole,
                  registration_date: userRegistrationData,
                },
              });
            }

            if (process.env.VUE_APP_ENABLE_TOOLTIP === 'true') {
              // Tooltip integration
              window.TooltipUserData = {
                id: userId,
                name: userName,
                email: userEmail,
                user_type: userLevel,
                user_role: userRole,
                registration_date: userRegistrationData,
              };
            }

            if (typeof __insp !== 'undefined') {
              // Inspectlet integration
              __insp.push(['identify', userId]);
              __insp.push(['tagSession', 'smart create']);
              __insp.push([
                'tagSession',
                {
                  email: userEmail,
                  userid: userId,
                  name: userName,
                  user_type: userLevel,
                  user_role: userRole,
                  registration_date: userRegistrationData,
                },
              ]);
            }
          }

          if (process.env.VUE_APP_ENABLE_SENTRY === 'true') {
            // Sentry integration
            Sentry.configureScope((scope) => {
              scope.setUser({
                id: userId,
                username: userUsername,
                email: userEmail,
              });
            });
          }
        }
        /* eslint-enable */

        // Freshchat Integration
        if (this.isFreshchatEnabled) {
          Vue.use(VueFreshchat, { appToken: process.env.VUE_APP_FRESHCHAT_APP_TOKEN });
          this.loadFreshchat = true;
        }

        if (userLevel === 'free' && !this.getTeamIdQueryUrl) {
          // free user will be handled by AccountController
          // window.location.href = `${process.env.VUE_APP_URL}/get-premium`;
        } else {
          this.isFetchingUserDetails = false;
        }
      })
      .catch((error) => {
        // Unauthenticated
        // if (error.response && error.response.status === 401) {
        // non logged in user will be handled by AccountController
        // window.location.href = `${process.env.VUE_APP_URL}/register`;
        //   return;
        // }
        this.setIsLoggedIn(false);
        // this.setShowRegister(true);
        this.setShowRegister(false);
        this.setShowLogin(true);
        console.error('error', error, this.isLoggedIn);
      })
      .then(() => {});

    if (typeof this.$route.query !== 'undefined') {
      if (
        typeof this.$route.query.utm_campaign !== 'undefined'
        && process.env.VUE_APP_UTM_CAMPAIGNS_FOR_TRIAL.split(',').includes(
          this.$route.query.utm_campaign,
        )
      ) {
        this.setRequiresSubscription(true);
      }

      if (typeof this.$route.query.demo !== 'undefined') {
        this.hasDemo = true;
        const removeBg = !(
          typeof this.$route.query.removebg !== 'undefined'
          && this.$route.query.removebg === 'false'
        );
        Api.getPersonalizedSmartCreate(this.$route.query.demo)
          .then((response) => {
            this.setTmpUserId();
            this.setConfirmRemoveBg(removeBg);
            this.selectImage(response.data.results.hero_url);
            this.setIsModalVisible(false);
            this.step = 3;
            this.hasDemo = false;
          })
          .catch((error) => {
            console.log(error);
            this.hasDemo = false;
          });
      }

      if (
        typeof this.$route.query.format !== 'undefined'
        && canvasSizesArray.includes(this.$route.query.format)
      ) {
        this.inspirationListFormat = this.$route.query.format;
      }
    }

    if (this.$router.currentRoute.params !== 'undefined') {
      if (
        typeof this.$router.currentRoute.params.type !== 'undefined'
        && this.validTypes.includes(this.$router.currentRoute.params.type)
      ) {
        this.setTmpUserId();
        this.smartCreate(this.$router.currentRoute.params.type);
      }
    }
  },
  mounted() {
    this.$root.$on('almost-done', this.onAlmostDone);
  },
  beforeDestroy() {
    this.$root.$off('almost-done', this.onAlmostDone);
  },
  watch: {
    step(val) {
      console.log('step', val);
    },
    tmpUserId() {
      this.smartCreateTrackingUpdate();
      if (this.getTeamIdQueryUrl) this.initTeam(this.getTeamIdQueryUrl);
    },
    isLoggedIn(val) {
      if (val) {
        this.isPageLoaded = true;
      }
    },
    isModalVisible() {
      if (this.isModalVisible) {
        document.documentElement.style.overflow = 'hidden';
        return;
      }

      document.documentElement.style.overflow = 'auto';
    },
    showRegister() {
      this.setSignUpPage('smart-create');
    },
  },
};

/* eslint-disable */
if (isProduction()) {
  // in prod mode, disable all console log, table and group
  console.log = function () {};
  console.table = function () {};
  console.group = function () {};
  console.debug = function () {};
}
/* eslint-enable */
</script>

<style lang="scss">
@import '~normalize.css';
@import '~@/assets/icomoon/style.scss';
@import '~@/assets/styles/darkModeVariables.scss';

.visuallyhidden,
.vh {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
}

body {
  background: #fff;
  font-weight: 500;
  scroll-behavior: smooth;

  * {
    &::-webkit-scrollbar {
      width: 7px;
      height: 7px;
    }

    &::-webkit-scrollbar-track {
      background: rgba(230, 230, 230, 0.5);
    }

    &::-webkit-scrollbar-thumb {
      background: $timelineGrey;
      transition: background 0.25s ease-in-out;
      border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: darken($timelineGrey, 5%);
    }
  }

  .crisp-client * {
    visibility: hidden !important;
    opacity: 0;
    pointer-events: none;
  }
}

button {
  border: 0;
  cursor: pointer;
  background: transparent;

  &:active,
  &:hover,
  &:focus {
    outline: 0;
    background: inherit;
  }
}

#app {
  font-family: $baseFont;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $baseBlack;
  overflow: hidden;
}

* {
  box-sizing: border-box;
}

.smart-create {
  #main-header,
  #main-content {
    background: var(--lightgrey100-darkgrey600);
  }

  #main-header {
    border-bottom: 1px solid var(--lightgrey400-darkgrey700);

    .logo__link {
      color: var(--black-white);
      background: transparent;

      .btn__content {
        display: none;
      }
    }

    .canvas-header {
      justify-content: flex-start;

      .header__slot-container {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      .btn-go-projects {
        margin-left: 20px;
        background: var(--lightgrey-darkgrey5);
        color: var(--blue700-white);

        &:hover {
          background: var(--blue700-darkgrey900);
          color: $light;
        }
      }
    }
  }

  #main-content {
    padding: 85px 30px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: auto;

    @include mobile() {
      display: block;
      padding-left: 20px;
      padding-right: 20px;
      padding-bottom: 40px;
    }

    div:not(.canvas-modal__header) > .title {
      font-size: 2.25em;
      font-weight: bold;
      text-align: center;
    }
  }

  @include mobile() {
    .canvas-modal {
      padding: 0;
    }

    .canvas-modal__header {
      padding: 20px 15px;
    }

    .canvas-modal__closebtn {
      right: 12px;
      width: 20px;
      height: 20px;
      padding: 0;
    }

    .canvas-modal__main {
      .content--top {
        padding: 10px;
      }

      .content--bottom {
        padding: 30px;
      }
    }

    .dialog-modal {
      align-items: flex-end;
      padding: 0;
    }

    .dialog-modal__content {
      padding: 30px;
    }

    .dialog-modal__buttons {
      flex-wrap: wrap;
      width: 100%;

      .btn {
        width: 100%;
        margin: 5px 0;
      }
    }

    .register,
    .login,
    .forgot-password,
    .subscribe {
      .canvas-modal__closebtn {
        display: none;
      }
    }

    .subscribe {
      top: 54px;
    }
  }
}

.remove-bg__failed-image {
  width: 175px;
}
</style>
