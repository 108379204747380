<template lang="pug">
BaseButton.btn-back-create(
  :is-canvas="true"
  @click="$emit('back')"
)
  i.icon.icon-left-arrow-long
</template>

<script>
export default {
  name: 'BackButton',
};
</script>

<style lang="scss">
.btn-back-create {
  position: absolute;
  left: 0;
  top: 0;
  padding: 8px;
  height: 54px;
  width: 54px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.125em;
  background: transparent;
  border: 1px solid var(--blue700-white) !important;
  color: var(--blue700-white);

  &:hover {
    background: var(--blue700-black800) !important;
    border-color: var(--blue700-black800) !important;
    color: $white;
  }

  .btn__content {
    display: flex;
    justify-content: center;
    align-items: center;

    span {
      display: inline-flex;
    }
  }

  @include mobile() {
    color: $darkGrey;
    border: 0;
    width: 36px;
    height: 36px;
    padding: 8px;
    left: -20px;
    top: -20px;
  };
}
</style>
