<template lang="pug">
  .upload-images
    BackButton(
      @back="$emit('back')"
    )
    h1.title Choose or Upload an image
    .upload-images__container
      .upload-images__upload
        span.text Supports JPEG and PNG
        button.btn-upload-image-preview(
          type="button"
          @click="openUploadModal(false)"
        )
          i.icon.icon-plus
          span Upload your image
      button.upload-images__sample(
        type="button"
        @click="openUploadModal(true)"
      ) Or try a sample image
    ReplaceImagesOverlay(
      v-if="isReplacingImages"
      isShow=true
      :preSelectedTab="preSelectedTab"
      @closeOverlay="closeReplaceImagesOverlay"
      @selected-image-object="selectImageAndClose"
    )
    ReplaceLogoOverlay(
      v-if="isReplacingLogo"
      isShow=true
      @closeOverlay="closeReplaceLogoOverlay"
      @select="selectLogo"
    )

    RemoveBgOption(
      v-if="showRemoveBgOption"
      @confirm="doConfirmRemoveBg"
      @close="setShowRemoveBgOption(false)"
      @back="reopenUploadModal"
    )

</template>

<script>

import { mapMutations, mapState } from 'vuex';

import UploadImagesItem from './children/UploadImagesItem.vue';
import ReplaceImagesOverlay from './PageOverlay/ReplaceImagesOverlay.vue';
import ReplaceLogoOverlay from './PageOverlay/ReplaceLogoOverlay.vue';
import DropZone from './PageOverlay/children/DropZone.vue';
import BackButton from './children/BackButton.vue';
import RemoveBgOption from './PageOverlay/RemoveBgOption.vue';
import uploadPermutationMixin from '@/components/mixins/upload-permutation-mixins';

export default {
  name: 'UploadImages',
  mixins: [uploadPermutationMixin],
  components: {
    DropZone,
    ReplaceImagesOverlay,
    ReplaceLogoOverlay,
    UploadImagesItem,
    BackButton,
    RemoveBgOption,
  },
  data() {
    return {
      heroData: {},
      isDragEnter: false,
    };
  },
  computed: {
    ...mapState('inspirationList', [
      'files',
      'tmpUserId',
      'heroIndex',
      'isLogo',
      'showRemoveBgOption',
      'confirmRemoveBg',
    ]),
  },
  methods: {
    ...mapMutations([
      'setIsModalVisible',
    ]),
    ...mapMutations('inspirationList', [
      'setFiles',
      'setHeroIndex',
      'setShowRemoveBgOption',
      'setConfirmRemoveBg',
      'setClippingImageJson',
      'setTmpUserFiles',
      'setInspirationTemplateTags',
      'setUserFileId',
    ]),
    closeReplaceImagesOverlay() {
      this.isReplacingImages = false;
    },
    closeReplaceLogoOverlay() {
      this.isReplacingLogo = false;
    },
    dragEnter() {
      this.isDragEnter = true;
    },
    dragLeave() {
      this.isDragEnter = false;
    },
    drop(file) {
      if (file) {
        const firstEmptyImageIndex = this.images.findIndex(image => image.url === '');
        if (firstEmptyImageIndex > -1) {
          this.images[firstEmptyImageIndex].url = this.getImageUrlFromFile(file);
        }
      }
      // must close the drop zone
      this.isDragEnter = false;

      // const tmpFiles = this.files;
      const tmpFiles = [];
      tmpFiles.push(file);
      this.setFiles(tmpFiles);
    },
    deleteImage(index) {
      this.images[index].url = '';

      // change the hero index
      let firstImageIndex = 0;

      for (let i = 0; i < this.images.length; i += 1) {
        if (this.images[i].url !== '') {
          firstImageIndex = i;
          break;
        }
      }

      this.setHeroIndex(firstImageIndex);

      const tmpFiles = [];

      this.setFiles(tmpFiles);
    },
    selectLogo(url) {
      console.log('selectLogo', url);
      this.selectedPreviewSampleIndex = -1;
      this.selectImage(url);
      this.setConfirmRemoveBg(false);
      this.uploadSmartCreateFile();
      this.$emit('next-step');
    },
    selectImageAndClose(selectedImageObject) {
      // 2. waiting for permutation to load
      this.smartCreateTrackingUpdate({ step: 2 });

      this.selectedPreviewSampleIndex = -1;
      let hasRemovedBg = false;
      this.heroData = {}; // clear the data
      if (typeof selectedImageObject.urls !== 'undefined') {
        // stock photos
        this.heroData.urlHd = selectedImageObject.urls.raw || selectedImageObject.urls.full || selectedImageObject.urls.regular || '';
        this.heroData.url = selectedImageObject.urls.regular || selectedImageObject.urls.small || selectedImageObject.urls.thumb || '';
        this.heroData.thumb = selectedImageObject.urls.small || selectedImageObject.urls.thumb || '';
        this.setUserFileId('');
        this.setShowRemoveBgOption(true);
      } else {
        /* users_files
         {
          "id": 367178,
          "user_id": 1,
          "folder_id": null,
          "file": "https://offeo-staging-assets.s3.us-east-2.amazonaws.com/user-uploads/1/image/1-850pQjAIHo.png",
          "preview_url": "https://offeo-staging-assets.s3.us-east-2.amazonaws.com/user-uploads/1/image/1-PbY0fLkfPn.png",
          "ext": "png",
          "is_preparing": false,
          "has_removed_bg": false
        }
       */
        // user files
        this.heroData.urlHd = selectedImageObject.file;
        this.heroData.url = selectedImageObject.file;
        this.heroData.thumb = selectedImageObject.preview_url;
        this.heroData.has_removed_bg = selectedImageObject.has_removed_bg || false;
        hasRemovedBg = selectedImageObject.has_removed_bg || false;
        this.setUserFileId(selectedImageObject.id);
      }

      // console.debug('selectImageAndClose', this.heroData, selectedImageObject)

      this.selectImage(this.heroData.url);
      this.closeReplaceLogoOverlay();

      if (hasRemovedBg) {
        this.setConfirmRemoveBg(true);
        this.setShowRemoveBgOption(false);
        this.uploadSmartCreateFile();
        this.$emit('next-step');
      } else {
        this.setShowRemoveBgOption(true);
      }
    },
    generateSceneWithImage(val) {
      // console.log(val);
      this.setTmpUserFiles([val]);
      this.$emit('next-step');
    },
    reopenUploadModal() {
      let tabIsSample = false;
      if (this.preSelectedTab === 'sample') tabIsSample = true;

      this.openUploadModal(tabIsSample);
      this.setShowRemoveBgOption(false);
    },
  },
};
</script>

<style lang="scss">
.upload-images {
  max-width: 700px;
  width: 100%;
  margin: 0 auto;
  position: relative;
  text-align: center;

  @include mobile() {
    padding-top: 20px;
  };

  .title {
    margin-top: 0;
    color: $white;
  }
}

.btn-generate-designs {
  margin-top: 40px;
}

.upload-images__container {
  text-align: center;
  width: 320px;
  margin: 50px auto 0;
}

.upload-images__upload {
  height: 380px;
  width: 320px;
}

.upload-images__upload {
  position: relative;
  border-radius: $componentBorderRadius;
  border: 2px solid $lightGreen;
  margin: 0 auto;

  .text {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    padding: 10px;
    color: $light;
    background: $lightGreen;
    width: 100%;
    font-size: 0.875em;
    z-index: 2;
  }

  .btn-upload-image-preview {
    position: absolute;
    top: 36px;
    left: 0;
    width: 100%;
    height: calc(100% - 36px);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    transition: background 0.25s ease-in-out;
    color: var(--black-white);

    &:hover {
      background: var(--lightgrey-lightgrey6);
    }
  }

  .icon {
    font-size: 4em;
    margin-top: -20px;
    color: var(--darkgrey800-lightgrey400);
    margin-bottom: 10px;
  }

  span {
    font-size: 0.875em;
  }
}

.upload-images__sample {
  margin-top: 20px;
  color: var(--blue700-white);
  text-decoration: underline;
  font-weight: 500;
}
</style>
