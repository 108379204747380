<template lang="pug">
.mobile-final-screen
  SuccessfulSubscription(
    v-if="successfulPayment"
    :smartCreate="true"
  )
  template(v-else)
    h1.title Awesome!<br>You're almost done!
    img.mobile-final-screen__image(src="@/assets/images/smart-create/mobile-offeo.gif")
    p(v-if="showEmailSent") Open OFFEO’s email from your computer and continue your video creation
</template>

<script>
import SuccessfulSubscription from '@/components/ProjectCanvas/PageOverlay/SuccessfulSubscription.vue';

export default {
  name: 'MobileFinalScreen',
  components: {
    SuccessfulSubscription,
  },
  props: {
    successfulPayment: { type: Boolean, default: false },
    showEmailSent: { type: Boolean, default: false },
  },
};
</script>

<style lang="scss">
.mobile-final-screen {
  text-align: center;
  background: $lightBg;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 11;
  height: 100%;
  width: 100%;
  padding: 30px 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  p {
    line-height: 1.4;
  }

  .title {
    font-size: 1.875em !important;
  }
}

.mobile-final-screen__image {
  width: 90%;
  display: block;
  margin: 0 auto;
}
</style>
