<template lang="pug">
.upload-images__item(
  :class="{ 'is-hero': isHero, 'has-image': url }"
)
  button.upload-images__add(
    v-if="!url"
    type="button"
    @click="$emit('open-modal', index)"
  )
    i.icon.icon-plus
    span Add {{ imageType }}
  template(v-else)
    img.upload-images__image(:src="url")
    .upload-images__hero(
      v-if="isHero"
    ) Hero Preview Image
    .upload-images__buttons
      BaseButton(
        v-if="!isHero"
        isPrimary=true
        @click="$emit('set-hero', index)"
      ) Use as Hero image
      BaseButton(
        @click="$emit('open-modal', index)"
      ) Replace
      BaseButton(
        isSalmon=true
        @click="$emit('delete', index)"
      ) Delete
</template>

<script>
export default {
  name: 'UploadImagesItem',
  props: {
    url: { type: String, default: '' },
    index: { type: Number },
    heroIndex: { type: Number },
    imageType: { type: String, default: '' },
  },
  computed: {
    isHero() {
      return this.index === this.heroIndex;
    },
  },
};
</script>

<style lang="scss">
.upload-images__item {
  border: 1px solid $defaultBorder;
  border-radius: $componentBorderRadius;
  overflow: hidden;
  width: 100%;
  margin: 15px;
  box-shadow: 0px 3px 4px rgba(10, 31, 68, 0.1), 0px 0px 1px rgba(10, 31, 68, 0.08);
  transition: box-shadow 0.25s ease-in-out;
  position: relative;

  &.has-image {
    background: url('~@/assets/images/backgrounds/transparent.png');
  }

  &:hover {
    box-shadow: 0px 26px 26px rgba(10, 31, 68, 0.12), 0px 0px 1px rgba(10, 31, 68, 0.1);
    border-color: $blue;

    & .upload-images__buttons {
      opacity: 1;
      visibility: visible;
    }
  }

  &::before {
    content: '';
    display: block;
    padding-top: 100%;
  }

  &.is-hero {
    border-color: $blue;
  }
}

.upload-images__add {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  border: 0;
  display: flex;
  justify-items: center;
  align-items: center;
  flex-wrap: wrap;
  color: $darkGrey;

  i,
  span {
    display: block;
    text-align: center;
    width: 100%;
  }

  .icon {
    font-size: 2em;
    margin-bottom: 5px;
  }
}

.upload-images__hero {
  position: absolute;
  padding: 8px;
  text-align: center;
  left: 0;
  bottom: 0;
  width: 100%;
  background: $blue;
  color: #fff;
  font-size: 0.875em;
}

.upload-images__buttons {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 10px;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.25s ease-in-out, visibility 0.25s ease-in-out;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;

  button {
    width: 100%;
    margin: 5px 0;
  }
}

.upload-images__image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
}
</style>
